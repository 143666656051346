<template>
  <div class="payment-order">
    <van-config-provider>
      <van-nav-bar :title="$t('title.order')" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
    </van-config-provider>
    
    <van-tabs v-model:active="tabActive" :type="$AppData.config.themeType ? 'card' : 'line'">
      <van-tab :title="$t('tabTitle.processing')"></van-tab>
      <van-tab :title="$t('title.historyData')"></van-tab>
    </van-tabs>
    <van-pull-refresh v-model="refreshing" @refresh="refreshData">
      <div class="order-wrap">
        <div class="order-list">
          <van-list
            v-if="dataList.length >0"
            v-model:loading="loading"
            :finished="finished"
            :finished-text="$t('common.noMore')"
            @load="loadData"
          >
            <div class="order-block" v-for="item in dataList" :key="item.sn" @click="goToDetail(item)">
              <div class="order-sn">{{item.sn}}</div>
              <div class="order-amount">{{item.amountStr}}PHP</div>
              <div class="order-time">{{item.bankName}}</div>
              <div class="order-status green">{{item.statusStr}}</div>
            </div>
          </van-list>
          <div v-else>
            <van-empty :description="$t('common.noData')"></van-empty>
          </div>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Toast } from 'vant';
import useClipboard from 'vue-clipboard3';
import i18n from '@/assets/lang/index';
import { queryPaymentProcessing, queryPaymentHistory } from "@/api";

export default {
  setup() {
    const { toClipboard } = useClipboard();
    const copy = async (text) => {
      try {
        await toClipboard(text);
        Toast(i18n.global.t('notify.copySuccess'));
      } catch (e) {
        console.error(e)
      }
    }
    
    const tabActive = ref(0);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const dataList = ref([]);
    return {
      copy,
      tabActive,
      loading,
      finished,
      refreshing,
      dataList,
    };
  },
  
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      current: 1,
      orderTimeout: '',
    };
  },
  
  watch: {
    tabActive() {
      this.refreshing = true;
      this.refreshData();
    }
  },
  
  mounted() {
    this.fetchOrderList();
  },

  methods: {
    fetchOrderList() {
      const doPost = this.tabActive ? queryPaymentHistory : queryPaymentProcessing;
      doPost({
        pageSizeStr: 10,
        pageNumberStr: this.current,
      }).then(res => {
        this.dataList = this.dataList.concat(res.data || []);
        this.current = res.pageNumber + 1;
        this.loading = false;
        if (res.totalNumber <= res.pageNumber * res.pageSize) {
          this.finished = true;
        }
      });
    },
    loadData() {
      if (this.refreshing) {
        this.dataList = [];
        this.current = 1;
        this.refreshing = false;
      }
      this.fetchOrderList();
    },
    refreshData() {
      // 清空列表数据
      this.finished = false;
            
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.loadData();
    },
    goToDetail(order) {
      localStorage.setItem('paymentData', JSON.stringify(order));
      this.$router.push('/payment/detail');
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.payment-order {
  .van-tabs--card {
    padding-top: 20px;
  }
  .order-wrap {
    margin: 0 16px;
    background: #fff;
    border-radius: 0 0 9px 9px;
    box-shadow: 0px 4px 16px 0px #EBEBEB;
  }
  .order-list {
    padding: 14px;
  }
}
</style>
